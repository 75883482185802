<template>
  <v-row class="pa-0">
    <v-col class="pa-0 flex-grow-0">
      <v-img
        class="rounded-10"
        :src="
          `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${selectedEvent.globalEventId}h4234h.jpg?alt=media`
        "
        :lazy-src="
          `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${selectedEvent.globalEventId}h4234h.jpg?alt=media`
        "
        max-width="100px"
      >
      </v-img>
    </v-col>

    <v-col class="pa-0 pl-10 pt-7">
      <h3 class="font-18 white--text">{{ selectedEvent.title }}</h3>
      <div v-if="stepperStep > 2" class="white--text subtitle-2">
        <v-icon class="mr-1 primary--text text--darken-4 subtitle-2" small>
          mdi-map-marker
        </v-icon>
        {{ selectedEvent.venue.split(',')[0] }}
      </div>
      <span
        v-if="stepperStep > 2"
        class="d-flex align-center white--text subtitle-2 "
      >
        <v-icon small class="mr-2 primary--text text--darken-4 subtitle-2">
          mdi-calendar-range
        </v-icon>
        {{ fullDateMonth(selectedEvent.startDate) }}
      </span>
      <div v-if="allowSwap" class="mt-3 white--text" style="max-width: 224px;">
        Huvitatud vahetusest
        <br />
        <span class="caption">{{ swapDetails }}</span>
      </div>
    </v-col>
    <v-col v-if="stepperStep === 5" class="pa-0 col-12">
      <div v-if="userAddedTickets.length">
        <div class="white--text font-18 font-weight-600 pt-12 mb-4">
          Komplektis {{ userAddedTickets.length }} piletit
        </div>
        <v-data-table
          :headers="[
            {
              text: 'Pilet',
              align: 'start',
              sortable: false,
              value: 'filename'
            },
            { text: 'Id', value: 'salesId' },
            { text: 'Piletitüüp', value: 'ticketType' },
            { text: 'Istekoht', value: 'seatDetails' },
            { text: 'Hind', value: 'price' }
          ]"
          :items="userAddedTickets"
          :items-per-page="5"
          class="elevation-0 transparent white--text"
          hide-default-footer
          light
          disable-sort
        ></v-data-table>
      </div>
      <div
        class="white--text font-18 text-right pr-4 pr-sm-12 mt-8 d-flex align-center"
      >
        <v-spacer></v-spacer>
        <span class="mr-4">
          {{ userAddedTickets.length ? 'Komplekt:' : 'Hind:' }}
        </span>
        <span class="display-1"> {{ getPrice() }}&nbsp;€</span>
      </div>
    </v-col>
    <v-col v-if="stepperStep === 5" class="pa-0 col-12 mt-12">
      <v-row
        class="pa-0"
        :class="{ 'flex-column-reverse': $vuetify.breakpoint.xsOnly }"
      >
        <v-col cols="12" md="6" class="pa-0 pr-0 pr-md-2">
          <v-btn
            v-if="isAuthenticated && ticketPosted"
            class="d-block mb-4 w-100 text-capitalize rounded-10"
            style=" height: 50px"
            color="#ffffff"
            @click="$router.replace('/', () => {})"
          >
            Esilehele
          </v-btn>
          <v-btn
            v-else
            class="d-block mb-4 text-capitalize rounded-10 darken-3 w-100"
            style="height: 50px"
            color="primary"
            @click="setStepperStep(stepperStep - 1)"
          >
            <v-icon
              left
              size="20"
              class="ma-0"
              style="position:absolute; left:0px"
              >mdi-chevron-left</v-icon
            >
            {{ $vuetify.lang.t('$vuetify.general.back') }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-0 pl-md-2">
          <v-btn
            v-if="isAuthenticated && ticketPosted"
            background-color="darkBlue"
            class="d-block white--text w-100 text-capitalize rounded-10 primary darken-3 mb-4 mb-md-0"
            style="height: 50px"
            text
            @click="goToUserTickets()"
          >
            Minu piletid
          </v-btn>
          <v-btn
            v-else
            :disabled="!isAuthenticated"
            class="d-block ml-0 mb-4 text-capitalize rounded-10 w-100"
            style="height: 50px"
            color="#ffffff"
            @click="$emit('addTicket')"
          >
            Lisa pilet
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'TicketSummary',
  mixins: [FormatDate],
  props: {
    ticketPosted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('events', [
      'selectedEvent',
      'stepperStep',
      'userAddedTickets'
    ]),
    ...mapGetters('authentication', ['isAuthenticated', 'user']),

    ticketSalesId: {
      get() {
        return this.$store.state.events.ticketSalesId
      },
      set(value) {
        this.$store.commit('events/setTicketSalesId', value)
      }
    },
    ticketType: {
      get() {
        return this.$store.state.events.ticketType
      },
      set(value) {
        this.$store.commit('events/setTicketType', value)
      }
    },
    swapDetails: {
      get() {
        return this.$store.state.events.swapDetails
      },
      set(value) {
        this.$store.commit('events/setSwapDetails', value)
      }
    },
    ticketSeat: {
      get() {
        return this.$store.state.events.ticketSeat
      },
      set(value) {
        this.$store.commit('events/setTicketSeat', value)
      }
    },
    selectedPrice: {
      get() {
        return this.$store.state.events.selectedPrice
      },
      set(value) {
        this.$store.commit('events/setSelectedPrice', value)
      }
    },
    allowSwap: {
      get() {
        return this.$store.state.events.allowSwap
      },
      set(value) {
        this.$store.commit('events/setAllowSwap', value)
      }
    },
    sellAsPatch: {
      get() {
        return this.$store.state.events.sellAsPatch
      },
      set(value) {
        this.$store.commit('events/setSellAsPatch', value)
      }
    }
  },
  methods: {
    getPrice() {
      //   let price = 0
      //   if (this.userAddedTickets.length) {
      //     price = this.userAddedTickets
      //       .map(ticket => ticket.price)
      //       .reduce((a, b) => a + b)
      //   } else {
      //     price = this.selectedPrice
      //   }
      //   return price
      // },
      if (this.userAddedTickets.length) {
        if (!this.sellAsPatch) {
          return this.userAddedTickets.reduce((a, b) => {
            return a + b.price
          }, 0)
        }
        return this.selectedPrice
      }
      return 0
    },
    setStepperStep(step) {
      this.$store.commit('events/setStepperStep', step)
    },
    goToUserTickets() {
      this.$store.commit('app/setSelectedProfileMenuItem', 'Sinu piletid')
      this.$router.push('/profiil', () => {})
    }
  }
}
</script>

<style lang="scss">
.theme--light.v-data-table thead tr th {
  color: #ffffff;
  font-weight: normal;
  font-size: 14px;
}

.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: rgba(0, 0, 0, 0.3);
}
</style>
